import React from "react";
import { navigate } from "gatsby-link";
import Layout from '../../components/Layout'
import Navbar from '../../components/Navbar'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  render() {
    return (
      <Layout>
        <div className="contactpage">
          <Navbar />
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <h1>Contact</h1>
                <div className="row">
                  <div className="col-md-6">
                    <h4>Adresse</h4>
                    Jocalis SA<br />
                    32 rue des Noirettes<br />
                    1227 Carouge<br />
                    022 329 21 74
                  </div>
                  <div className="col-md-6">
                    <h4>Horaires d'ouverture</h4>
                    <div class="row"><div class="col-md-12"><div class="row row--line small"><div class="col-xs-4"><span class="span--line">Lundi</span></div><div class="col-xs-8 align-right"><span class="span--line">8h – 12h / 13h30 – 17h30</span></div></div><div class="row row--line small"><div class="col-xs-4"><span class="span--line">Mardi</span></div><div class="col-xs-8 align-right"><span class="span--line">8h – 12h / 13h30 – 17h30</span></div></div><div class="row row--line small"><div class="col-xs-4"><span class="span--line">Mercredi</span></div><div class="col-xs-8 align-right"><span class="span--line">8h – 12h / 13h30 – 17h30</span></div></div><div class="row row--line small"><div class="col-xs-4"><span class="span--line">Jeudi</span></div><div class="col-xs-8 align-right"><span class="span--line">8h – 12h / 13h30 – 17h30</span></div></div><div class="row row--line small"><div class="col-xs-4"><span class="span--line">Vendredi</span></div><div class="col-xs-8 align-right"><span class="span--line">8h – 12h / 13h30 – 17h30</span></div></div><div class="row row--line small"><div class="col-xs-4"><span class="span--line">Samedi</span></div><div class="col-xs-8 align-right"><span class="span--line">Férmé</span></div></div><div class="row row--line small"><div class="col-xs-4"><span class="span--line">Dimanche</span></div><div class="col-xs-8 align-right"><span class="span--line">Férmé</span></div></div></div></div>
                  </div>
                </div>
                <h4>Envoyer un message</h4>
                <form
                  name="contact"
                  method="post"
                  action="/contact/thanks/"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={this.handleSubmit}
                >
                  {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                  <input type="hidden" name="form-name" value="contact" />
                  <div hidden>
                    <label>
                      Don’t fill this out:{" "}
                      <input name="bot-field" onChange={this.handleChange} />
                    </label>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor={"name"} >Votre nom</label>
                    <input className="input" type={"text"} name={"name"} onChange={this.handleChange} id={"name"} required={true} />
                  </div>
                  <div className="field">
                    <label className="label" htmlFor={"email"}>Votre email</label>
                    <input className="input" type={"email"} name={"email"} onChange={this.handleChange} id={"email"} required={true} />
                  </div>
                  <div className="field">
                    <label className="label" htmlFor={"message"}>Votre message</label>
                    <textarea className="textarea" name={"message"} onChange={this.handleChange} id={"message"} required={true} />
                  </div>
                  <div className="field">
                    <button className="btn-primary is-link" type="submit">Envoyer</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}