import React from 'react'
import { Link } from 'gatsby'

const Navbar = class extends React.Component {
 
 render() {
   return (
  
  <nav className="header" role="navigation" aria-label="main-navigation">
    <div className="container">
      <div className="row">
        <div className="col-xs-6">
          <Link to="/" className="logo" title="Logo">Jocalis</Link>
        </div>
        <div className="col-xs-6">
          <ul className="menu">
            <li><Link activeClassName="active" className="menu-link" to="/contact">Contact</Link></li>
          </ul>
        </div>
      </div> 
    </div>
  </nav>
  )}
}

export default Navbar
